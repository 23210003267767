/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:45:27
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 16:45:47
 */
import ChartContainer from '../modules/chartContainer'
import BarChart from '../modules/barChart'

export default {
  name: 'AppLike',
  components: {
    ChartContainer,
    BarChart
  },
  data () {
    return {
      titleStyle: {
        width: '64px'
      },
      valueStyle: {
        width: '48px'
      }
    }
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    formatData () {
      const self = this
      if (!Array.isArray(self.data) || self.data.length === 0) {
        return []
      }
      return self.data.reduce((res, { label_name: name, user_count: count, total }, index) => {
        const rate = Math.round(count * 10000 / total) / 100
        res = res.concat({
          name,
          count,
          rate
        })
        return res
      }, []).filter(({ rate }) => rate > 0)
    },

    level () {
      const self = this
      if (!Array.isArray(self.formatData) || self.formatData.length === 0) {
        return 0
      }
      const max = self.formatData.reduce((last, { rate: cur }) => {
        return last > cur ? last : cur
      }, 0)
      let level = 1
      while (level < max) {
        level += 1
      }
      return level
    },

    chartData () {
      const self = this
      if (self.level === 0) {
        return []
      }
      const levelCount = Math.ceil(self.level * self.data[0].total / 100)
      return self.formatData.map(({ name, count, rate }) => {
        return {
          name,
          value: rate.toFixed(2) + '%',
          percent: Math.round(count * 10000 / levelCount) / 100 + '%'
        }
      }).slice(0, 10)
    }
  }
}
