import { render, staticRenderFns } from "./appLike.vue?vue&type=template&id=6cb42d85&scoped=true&"
import script from "./appLike.js?vue&type=script&lang=js&"
export * from "./appLike.js?vue&type=script&lang=js&"
import style0 from "./appLike.scss?vue&type=style&index=0&id=6cb42d85&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb42d85",
  null
  
)

export default component.exports