/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:46:13
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-07 11:47:21
 */
import ChartDataTool from '@/service/chartDataTool'
import ChartContainer from '../modules/chartContainer'
import BarChart from '../modules/barChart'

export default {
  name: 'Career',
  components: {
    ChartContainer,
    BarChart
  },
  data () {
    return {
      titleStyle: {
        width: '88px'
      },
      valueStyle: {
        width: '48px'
      },
      colors: [
        ['#2DB8FF', '#1482FF'],
        ['#14C6CA', '#58F6F9'],
        ['#EF50C1', '#F38AFF'],
        ['#516598', '#1E2537'],
        ['#47BDFF', '#2C68FF'],
        ['#58F6F9', '#14C6CA'],
        ['#EF50C1', '#F38AFF'],
        ['#8ED6FF', '#BFD1FF'],
        ['#E6E6E6', '#E6E6E6']
      ]
    }
  },
  props: {
    data: {
      type: Array,
      default: () => ([
        // {
        //   name: '男',
        //   percent: '50.00%',
        //   barColors: ['rgba(45, 184, 255, 1)', 'rgba(20, 130, 255, 1)']
        // },
        // {
        //   name: '女',
        //   percent: '45.00%',
        //   barColors: ['rgba(239, 80, 193, 1)', 'rgba(243, 138, 255, 1)']
        // },
        // {
        //   name: '其他',
        //   percent: '5.00%',
        //   barColors: ['rgba(30, 37, 55, 1)', 'rgba(81, 101, 152, 1)']
        // }
      ])
    }
  },
  computed: {
    chartData () {
      const self = this
      if (!Array.isArray(self.data) || self.data.length === 0) {
        return []
      }
      const tool = new ChartDataTool()
      return self.data.reduce((res, { label_name: name, user_count: count, total }, index) => {
        const rate = tool.getRate(count, total)
        const percent = rate.toFixed(2) + '%'
        res = res.concat({
          name,
          percent,
          barColors: self.colors[index]
        })
        return res
      }, [])
    }
  }
}
